import autorenweltTeam from "../images/presse/autorenwelt-team_wilhelm-angelika-sandra.jpg"
import sandra1 from "../images/presse/sandra-uschtrin_foto_christoph-hellhake_small.jpg"
import sandra2 from "../images/presse/sandra-uschtrin_foto_eva-stuetze_small.jpg"
import federweltCover from "../images/presse/aktuelles_federwelt-cover.jpg"
import selfpublisherCover1 from "../images/presse/aktuelles_selfpublisher-cover.jpg"
import selfpublisherCover2 from "../images/presse/derselfpublisher_u1_300dpi.jpg"
import spIcon from "../images/presse/sp-icon.jpg"
import spLogo from "../images/presse/sp-logo.jpg"
import federweltIcon from "../images/presse/federwelt-icon.jpg"
import federweltLogo from "../images/presse/federwelt-logo.jpg"
import heuteSchonGesch1 from "../images/books/heuteschongeschrieben-bd1und2-300dpi-rgb.png"
import heuteSchonGesch2 from "../images/books/heuteschongeschrieben-bd1-300dpi-rgb.png"
import heuteSchonGesch3 from "../images/books/heuteschongeschrieben-bd2-300dpi-rgb.png"
import handbuch from "../images/books/handbuch.jpg"
import uschtrinIcon from "../images/presse/uschtrin_icon.png"
import uschtrinLogo from "../images/presse/uschtrin_logo.jpg"
import autorenweltIcon from "../images/presse/autorenwelt_icon.png"
import autorenweltLogo from "../images/presse/autorenwelt_logo.png"

export const photos = [
  {
    imageSrc: autorenweltTeam,
    imageName: "autorenweltTeam",
    title: "Autorenwelt: Wilhelm Uschtrin, Angelika Fuchs, Sandra Uschtrin",
  },
  {
    imageSrc: sandra1,
    imageName: "sandra1",
    title: "Sandra Uschtrin",
  },
  {
    imageSrc: sandra2,
    imageName: "sandra2",
    title: "Sandra Uschtrin",
  },
  {
    imageSrc: selfpublisherCover1,
    imageName: "selfpublisherCover1",
    title: "Cover der aktuellen Ausgabe des selfpublishers",
    description: "Deutschlands 1. Selfpublishing-Magazin",
  },
  {
    imageSrc: selfpublisherCover2,
    imageName: "selfpublisherCover2",
    title: "Cover der Startausgabe des selfpublishers Oktober 2015",
    description: "Deutschlands 1. Selfpublishing-Magazin",
  },
  {
    imageSrc: spIcon,
    imageName: "spIcon",
    title: "Icon des Magazins »der selfpublisher«",
  },
  {
    imageSrc: spLogo,
    imageName: "spLogo",
    title: "Schriftzug »der selfpublisher«",
  },
  {
    imageSrc: federweltCover,
    imageName: "federweltCover",
    title: "Cover der aktuellen Federwelt",
    description: "Zeitschrift für Autorinnen und Autoren",
  },
  {
    imageSrc: federweltIcon,
    imageName: "federweltIcon",
    title: "Icon des Magazins »Federwelt«",
  },
  {
    imageSrc: federweltLogo,
    imageName: "federweltLogo",
    title: "Schriftzug »Federwelt«",
  },
  {
    imageSrc: heuteSchonGesch1,
    imageName: "heuteSchonGesch1",
    title: "Cover von Diana Hillebrands Schreibratgebern",
    description: "»Heute schon geschrieben?«, Band 1 und 2 (Gesamtedition)",
  },
  {
    imageSrc: heuteSchonGesch2,
    imageName: "heuteSchonGesch2",
    title: "Cover von Diana Hillebrands Schreibratgeber, Band 1",
  },
  {
    imageSrc: heuteSchonGesch3,
    imageName: "heuteSchonGesch3",
    title: "Cover von Diana Hillebrands Schreibratgeber, Band 2",
  },
  {
    imageSrc: handbuch,
    imageName: "handbuch",
    title: "Cover der 8. Ausgabe des »Handbuchs für Autorinnen und Autoren«",
  },
  {
    imageSrc: uschtrinIcon,
    imageName: "uschtrinIcon",
    title: "Icon des Uschtrin Verlags",
  },
  {
    imageSrc: uschtrinLogo,
    imageName: "uschtrinLogo",
    title: "Schriftzug des Uschtrin Verlags",
  },
  {
    imageSrc: autorenweltIcon,
    imageName: "autorenweltIcon",
    title: "Icon der Autorenwelt",
  },
  {
    imageSrc: autorenweltLogo,
    imageName: "autorenweltLogo",
    title: "Schriftzug der Autorenwelt",
  },
]
