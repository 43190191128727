import { graphql } from "gatsby"
import Img from "gatsby-image"
import { FaExternalLinkAlt } from "react-icons/fa"

import Layout from "../components/layout"
import SEO from "../components/seo"

/** @jsx jsx */
import { jsx, Card, Grid, Heading, Text, Styled } from "theme-ui"

import * as content from "../content/presse.js"
import presseMeldungPdf from "../content/misc/pressemitteilung_buecherfrau-des-jahres-2019_6mai2019.pdf"

const Page = ({ data, location }) => (
  <Layout>
    <SEO
      location={location}
      title="Presse"
      descrption="Große und kleine Pressemeldungen"
    />
    <Heading as="h1" variant="siteTitle">
      Presse
    </Heading>
    <Text sx={{ fontSize: 0, pb: 3 }}>
      Auf dieser Seite finden Sie unsere Pressemeldungen und Pressefotos. Bitte
      zögern Sie nicht, uns anzurufen, wenn Sie Fragen haben.
    </Text>
    <Heading sx={{ pb: 3 }} as="h2">
      Pressemitteilungen
    </Heading>
    <Text sx={{ pb: 3, fontSize: 1 }}>
      <span style={{ fontWeight: "bold" }}>06.05.2019:</span> Sandra Uschtrin
      wird BücherFrau des Jahres 2019 (
      <Styled.a
        href="https://www.autorenwelt.de/blog/branchen-news/sandra-uschtrin-wird-buecherfrau-des-jahres-2019"
        target="_blank"
        rel="noopener noreferrer"
      >
        Link 1
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />,{" "}
      <Styled.a
        href="https://www.buecherfrauen.de/inhalt/presse/presse-detail/buecherfrau-des-jahres-2019-sandra-uschtrin"
        target="_blank"
        rel="noopener noreferrer"
      >
        Link 2
      </Styled.a>{" "}
      <FaExternalLinkAlt sx={{ fontSize: 14 }} />,{" "}
      <Styled.a href={presseMeldungPdf}>PDF</Styled.a>)
    </Text>
    <Heading sx={{ pb: 3, pt: 3 }} as="h2">
      Fotos
    </Heading>
    <Grid variant="default" gap={4} columns={[2, null, 4]}>
      {content.photos.map(photo => (
        <Card sx={{ textAlign: "center" }}>
          <a
            href={photo.imageSrc}
            aria-label={`Download-Link für das Bild "${photo.ImageName}"`}
          >
            <Img fluid={data[photo.imageName].childImageSharp.fluid} />
          </a>
          <Heading sx={{ pt: 2, fontSize: 1 }} as="h3">
            {photo.title}
          </Heading>
          {photo.description && (
            <Text sx={{ fontSize: 0 }}>{photo.description}</Text>
          )}
        </Card>
      ))}
    </Grid>
  </Layout>
)

export const query = graphql`
  query {
    autorenweltTeam: file(
      relativePath: {
        eq: "presse/autorenwelt-team_wilhelm-angelika-sandra.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    sandra1: file(
      relativePath: {
        eq: "presse/sandra-uschtrin_foto_christoph-hellhake_small.jpg"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    sandra2: file(
      relativePath: { eq: "presse/sandra-uschtrin_foto_eva-stuetze_small.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    federweltCover: file(
      relativePath: { eq: "presse/aktuelles_federwelt-cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    selfpublisherCover1: file(
      relativePath: { eq: "presse/aktuelles_selfpublisher-cover.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    selfpublisherCover2: file(
      relativePath: { eq: "presse/derselfpublisher_u1_300dpi.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    spIcon: file(relativePath: { eq: "presse/sp-icon.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    spLogo: file(relativePath: { eq: "presse/sp-logo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    federweltIcon: file(relativePath: { eq: "presse/federwelt-icon.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    federweltLogo: file(relativePath: { eq: "presse/federwelt-logo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    heuteSchonGesch1: file(
      relativePath: { eq: "books/heuteschongeschrieben-bd1und2-300dpi-rgb.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    heuteSchonGesch2: file(
      relativePath: { eq: "books/heuteschongeschrieben-bd1-300dpi-rgb.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    heuteSchonGesch3: file(
      relativePath: { eq: "books/heuteschongeschrieben-bd2-300dpi-rgb.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    handbuch: file(relativePath: { eq: "books/handbuch.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    uschtrinIcon: file(relativePath: { eq: "presse/uschtrin_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    uschtrinLogo: file(relativePath: { eq: "presse/uschtrin_logo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    autorenweltIcon: file(relativePath: { eq: "presse/autorenwelt_icon.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    autorenweltLogo: file(relativePath: { eq: "presse/autorenwelt_logo.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

export default Page
